<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "yongYeCheng",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            '#初始环境必须成功(图1)',
                            '#需要角色已有进入梦境第四章-姐妹情深的权限,如果没有请先完成其他前置章节'
                        ],
                        img:[
                            "com/init.png",
                            "1.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到永夜城页",
                            "#按图1说明参数:",
                            "1.必填项-开始方式:根据实际需求选择开始方式",
                            "角色在永夜城场景,自动加入梦境开始:可以用连续任务,上一个任务结束后,传送到永夜城T点,以此方式开始永夜城任务",
                            "角色已进入梦境,直接开始任务:手动对话梦婆,进入场景后,以此方式开始永夜城任务",
                        ],
                        img:[
                            "2.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            "#注意识别区域,防止误使用药品食物(图1)",
                            "#可以把马哨,鹿,娇子,御风放到快捷栏上,移动时候自动使用(图2)",
                            '以上设置好,开始任务',
                        ],
                        img:[
                            "4.png",
                            "3.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            setTimeout(res =>{
                this.$refs.row.initData(this.list);
                console.log(this.$options.name);
            },100)
        }
    }
</script>

<style scoped>

</style>
